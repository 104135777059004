<template>
  <div class="myOrder">
    <van-nav-bar
        title="My Orders"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div v-if="orders.length" class="list">
      <div v-for="(item,index) in orders" :key="index" class="item" @click="router.push('/myOrder/detail?id=' + item.order_id)">
        <img class="icon" :src="item.images" alt="">
        <div class="text">
          <div class="name">{{ item.name }}</div>
          <div class="time">Order Date: {{ item.add_time }}</div>
        </div>
        <img class="arrow" src="@/assets/imgs/icon_arrowR.png" alt="">
      </div>
      <div v-show="totalPage > 1 && !noMore" class="more" @click="handleMore">See More</div>
    </div>
    <div v-else class="noData">No data available</div>
  </div>

  <TabBar/>
</template>

<script setup>
  import TabBar from "@/components/TabBar";
  import { useRouter } from 'vue-router'
  import { orderList } from '@/request/api'
  import { ref } from 'vue'

  const router = useRouter()

  let page = 1
  const orders = ref([])
  const totalPage = ref(0)
  const noMore = ref(true)
  getList()
  function getList() {
    orderList({
      address: sessionStorage.getItem('address'),
      // address: '0x0000000000000000000000000000000000000000',
      page,
      limit: 10
    }).then(res => {
      if(res.success) {
        if(page === 1) {
          orders.value = res.data.list
        } else {
          orders.value.push(...res.data.list)
        }
        totalPage.value = res.data.totalPage
        noMore.value = page === totalPage.value
      }
    })
  }

  function handleMore() {
    page++
    getList()
  }
</script>

<style lang="less" scoped>
.myOrder{
  .list{
    padding: 16px 0;
    .item{
      background: #101010;
      height: 80px;
      box-sizing: border-box;
      padding: 10px 0 10px 24px;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 10px;

      .icon{
        width: 60px;
        height: 60px;
        border-radius: 20px;
        margin-right: 10px;
      }
      .text{
        .name{
          color: #F8F8F8;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }
        .time{
          color: #697377;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .arrow{
        position: absolute;
        width: 16px;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .more{
      font-size: 14px;
      text-align: center;
      padding: 10px 0;
    }
  }
  .noData{
    padding-top: 20px;
    text-align: center;
    font-size: 13px;
  }
}
</style>
